import React from 'react';
export default() => (
    <div class="card-group">
        <div class="card-body">
            <h4 class="card-title">… our services</h4>
            <p>Our primary focus is Microsoft SQL Server performance tuning, 
              but we offer services across the entire SQL Server development platform</p>
            
            </div>
            <div class="card-body">
            <h5 class="card-title">SQL Server Database Performance Review</h5>
            <p>An initial review of your database to understand the performance issues you are facing.  provide a report
               detailing the causes of any performance problems you are facing
               and recommend the next steps</p>
            </div>

            <div class="card-body">
            <h5 class="card-title">SQL Server Physical Schema Review</h5>
            <p>The physical data schema is the most critical aspect affecting the performance of the database.
              Our review, ensures you get the design right, to maximise the database performance.
              Ideally, we would review a database before development starts.
              Any Database Performance Review will assess the existing schema and recommend steps to evolve the schema into a more optimal structure.</p>
              <p>...more</p>
            </div>

            <div class="card-body">
            <h5 class="card-title">SQL Server Best Practices</h5>
            <p>We can assist with development best practices, implementing:
               SQL Server Source Control, Database Unit Testing, Database Continuous Deployment</p>
            </div>



    </div>

)
