import React, {useEffect} from 'react';
import Home from './home';
import Founder from './founder';

export default () => {

    useEffect(() => {
        document.title = `Microsoft SQL Server Performance Tuning Consultants - London - SQL Unlimited`;
      });
    return(
    
    <div class="card-group">
        <Home/>
        <Founder/>
    </div>


    )}
