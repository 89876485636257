import Home from './scenes/home/index';
import AboutUs from './scenes/aboutus';
import Services from './scenes/services/index';
import Sqlserverperformancetuning from './scenes/services/sqlserverperformancetuning/index';
import Sqlserverpractices from './scenes/sqlserverpractices/index';
import Approach from './scenes/approach/index';

export default [
   {path:"/"                              ,title:"Home"                         ,component:Home}
   ,{path:"/services"                     ,title:"Services"                     ,component:Services}

  // ,{path:"/approach"                      ,title:"approach"                     ,component:Approach}
  // ,{path:"/sqlserverpractices"            ,title:"sqlserverpractices"           ,component:Sqlserverpractices}
  // ,{path:"/sqlserverperformancetuning"    ,title:"sqlserverperformancetuning"   ,component:Sqlserverperformancetuning}
  ,{path:"/aboutus"                       ,title:"About Us"                     ,component:AboutUs}
]