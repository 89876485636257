import React, {useEffect} from 'react';
import { Navbar, Nav } from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from "react-router";
import RouteList from '../Routes';

const NavBar = (props) => {
  const { location } = props;
  return (
    <Navbar className="shadow" sticky="top" bg="light" expand="sm">
<main role="main" class="flex-shrink-0">
    <div class="container">
      <div class="row">


    <Navbar.Brand className="navbar-brand" href="/">
    <div class="b1">SQL </div>
        <div class="b2">Unlimited
            <hr className="bar"/>
            <div class="tagline">...performance guaranteed</div>
        </div>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" activeKey={location.pathname}>
        {
          RouteList.map(r => <Nav.Link href={r.path} activeClassName="active">{r.title}</Nav.Link>)
        }

        {/*
        <Nav.Link href="/sqlserverperformancetuning">Tuning</Nav.Link>
        <Nav.Link href="/services">Services</Nav.Link>
        <Nav.Link href="/sqlserverpractices">SQL Practices</Nav.Link>
        */}
        </Nav>
    </Navbar.Collapse>


    </div>
        </div>
      </main>
      </Navbar>
  );
};
const Header = withRouter(NavBar);
export default Header;