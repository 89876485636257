import React, {useEffect} from 'react';
import Image from 'react-bootstrap/Image'
import ContactImg from '../img/contact_white.png';

export default () => {

  useEffect(() => {
    document.title = `Microsoft SQL Server Performance Tuning Consultants - London - SQL Unlimited`;
  });

  return(
    <div class="card-group">
        <div class="card-body">
            <h4 class="card-title">… about us</h4>
            <p>SQL Unlimited is based in Buckinghamshire, but many of our clients are based in or around London.
              <br/>We can work with clients, remotely across the world, but can work face to face with clients in the south of England.</p>
            <h4 class="card-title">… contact us</h4>
            
            <p>SQL Unlimited can be contacted via:<br/>
            <img src={ContactImg} alt="contact"/></p>
            <p>We aim to respond within a couple of hours.</p>
            <p>We are a team of hardcore SQL Server consultants. We don't employ office, sales or marketing staff, so please bear with us, while we respond.</p>

        </div>
    </div>
)
};
/*
//grab a form
const form = document.querySelector('.form-inline');

//grab an input
const inputEmail = form.querySelector('#inputEmail');


//config your firebase push
const config = {
    apiKey: "AIzaSyCfI_1234455glTdTNjFA3kAvmimlU",
    authDomain: "example-9167e.firebaseapp.com",
    databaseURL: "example-9167e.firebaseio.com",
    projectId: "example-9167e",
    storageBucket: "example-9167e.appspot.com",
    messagingSenderId: "6271234586832"
};


//create a functions to push
    function firebasePush(input) {


        //prevents from braking
        if (!firebase.apps.length) {
            firebase.initializeApp(config);
        }

        //push itself
        var mailsRef = firebase.database().ref('emails').push().set(
            {
                mail: input.value
            }
        );

    }

//push on form submit
    if (form) {
        form.addEventListener('submit', function (evt) {
            evt.preventDefault();
            firebasePush(inputEmail);

            //shows alert if everything went well.
            return alert('Data Successfully Sent to Realtime Database');
        })
    }

    export default() => (
        <div>Contact Page
    
    
    <form class="form-inline d-flex">
      <input type="email" class="form-control               flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"                   id="inputEmail" placeholder="Enter email               address..."/>
      <button type="submit" class="btn btn-primary mx-auto">Subscribe</button>
    </form>
    
    
        </div>
    
    
    
    
    )
    */