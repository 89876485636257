import React, {Component, useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import ReactGA from 'react-ga';
import createHistory from 'history/createBrowserHistory'

import Header from './components/header';
import Footer from './components/footer';
import PageNotFound404 from './scenes/404';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import RouteList from './Routes';

export default (props) => {
  
  ReactGA.initialize('UA-152046509-1');
  //alert(window.location.pathname);
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);

  // const history = createHistory()
  // history.listen((location, action) => {
  //   alert(location.pathname);
  //   ReactGA.set({ page: location.pathname });
  //   ReactGA.pageview(location.pathname);
  // });

  return (
    <Router>
      <Header />
      <main role="main" class="flex-shrink-0 mt-3">
        <div class="container">
          <div class="row">
            <TransitionGroup className="transition-group">
              <CSSTransition classNames="fade" timeout={300}>
                <section>
                <Switch>
                  {
                    RouteList.map(r => <Route exact path={r.path} component={r.component} />)
                  }
                  {/* <Route path="/aboutus" component={Aboutus} /> */}
                  <Route path="/" component={PageNotFound404} /> 
                </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </main>
      <Footer/>
    </Router>
    );
}
//export default App;
