import React from 'react';

export default() => (
    <div class="col-md-8 order-md-1" >
        <div class="card-body">
            <h4 class="card-title">… making SQL Server fast</h4>
            <p>SQL Unlimited specialises in performance tuning Microsoft SQL Server databases.
              Often our performance improvements can be dramatic.</p>
            <p>In addition to performance tuning, we can offer a range of services, such as:</p>
            <ul>
            <li>SQL Server database schema review</li>
            <li>SQL Server development best practices</li>
            <li>SQL Server ongoing support</li>
            <li>SQL Server database migrations</li>
            </ul>
            <h4 class="card-title">… why is my SQL Server database slow</h4>
            <p>There are far too many factors to mention, but common cause are:</p>
            <ul>
            <li>Incorrect or missing indexes</li>
            <li>Out of date or missing statistics on data</li>
            <li>Poorly written stored procedures</li>
            <li>Complex queries or excessive number joins</li>
            <li>Cursors or while loops, acting on one row at a time</li>
            <li>Temporary tables / table variables without indexes</li>
            <li>Bad execution plan caching</li>
            <li>Dynamic SQL without schema</li>
            <li>Poor database design</li>
            
            </ul>
            <h4>... performance guaranteed</h4>
        </div>
    </div>
)
