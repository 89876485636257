import React from 'react';
//import MyPage from '../common/MyPage';

export default() => (
    
    <div class="col-md-4 order-md-2 mb-4" >
        {/*<img src="..." class="card-img-top" alt="..."/>*/}
        <div class="card-body bg-light shadow mt-2">
            <h5 class="card-title">Founder</h5>
            <p class="card-text">SQL Unlimited, was founded by a former Microsoft software engineer, with a passion for developing high performing SQL Server databases.</p>
            <p class="card-text">During his time at Microsoft he worked on the database which served the worldwide MSN websites, to over 300M unique visitors per month.</p>
            <p class="card-text">Since then, he has gone on to develop databases for Barclays, HSBC, Bank of America, Royal Bank of Canada and Royal Bank of Scotland</p>
            {/*<a href="#" class="btn btn-primary">Go somewhere</a>*/}
        </div>
    </div>


    )