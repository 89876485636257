import React from 'react';
import Image from 'react-bootstrap/Image'

// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
//import { fab } from '@fortawesome/free-brands-svg-icons'

import ContactImg from '../img/contact.png';

export default props => (
    <footer class="footer mt-auto py-3 flex-shrink-0 bg-light">
        <div class="container">
            <div class="card-group">
                <div class="col-md-8 order-md-1" >
                    <div class="card-body p-0">
                        <span class="text-muted">© SQL Unlimited 2016 - 2019</span>
                    </div>
                    <a href="https://www.linkedin.com/company/sql-unlimited/" class="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-4a54f8c" target="_blank">
                        <span class="elementor-screen-only">Linkedin</span>
                        <i class="fab fa-linkedin"></i>
                    </a>
                </div>
                <div class="col-md-4 order-md-2 bg-light" >
                    <div class="card-body p-0">
                        <img src={ContactImg} alt="contact"/> 
                    </div>
                </div>
            </div>
        </div>
    </footer>
)